<template>
  <div class="container h-100 th-px-24-md">
    <header-with-back :to="{ name: 'home', params: $route.params }">
      {{ $t(`product.step.${this.$route.params.type}.title`) }}
    </header-with-back>
    <div
      class="d-flex align-items-center justify-content-center flex-column h-100 th-py-12 th-py-48-md"
    >
      <page-loader v-if="loading" />
      <component
        v-else
        v-model="model"
        :is="questionsComponent"
        :save-data="saveData"
        :on-submit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import HeaderWithBack from "../../../components/Project/HeaderWithBack";
import { componentByType } from "../../../utils/service/Questionnaire";
import PageLoader from "../../../components/PageLoader";

export default {
  name: "Questionnaire",
  components: {
    PageLoader,
    HeaderWithBack,
  },
  data() {
    return {
      model: {},
      loading: true,
    };
  },
  async beforeCreate() {
    try {
      this.model =
        (
          await this.$axios.get(
            `/api/v1/business/data/${this.$route.params.type}`
          )
        )?.params || this.model;
    } catch {
      // Do nothing
    } finally {
      this.loading = false;
    }
  },
  computed: {
    questionsComponent() {
      return componentByType(this.$route.params.type);
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.type === "data_usage_purpose") {
      return next({
        name: "questionnaire_type_data_usage_purpose",
        params: { ...to.params },
      });
    }
    next();
  },
  methods: {
    async saveData(model) {
      return await this.$axios.post(`/api/v1/business/data`, {
        locale: this.$route.params?.locale || "en",
        type: this.$route.params.type,
        params: model,
      });
    },
    onSubmit() {
      this.$router.push({ name: "home", params: { ...this.$route.params } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
